@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap");
#root {
  overflow-x: hidden;
}

:root {
  --h-height: 70px;
  --f-height: 100px;
  --transition: 300ms ease;
  --font-light: rgba(255, 255, 255, 0.9);
  --gray: #8a8a8a;

  @media (max-width: 767px) {
    --h-height: 60px;
    --f-height: 60px;
  }
}

:root[data-theme="light"] {
  --total: #fff;
  --total-invert: #000;
  --bg-main: #fcfcfc;
  --bg-2: #f05d66;
  --bg-3: #fffbf9;
  --bg-4: #fbd9d6;
  --font-color: #3e3e3e;
  --accent: linear-gradient(to right, #eb3349, #f45c43);
  --accent-br: #ff0000;
  --hr-color: #fceeee;
  --gray: #e0e0e0;
  --light-gray: #f5f5f5;
  --search-btn: #e13f3f;
  --btn-1-bg: #fcfcfc;
  --btn-1-color: #b92730;
  --btn-2-bg: transparent;
  --btn-2-border: #938f99;
  --btn-2-color: #fceeee;
  --btn-3-bg: transparent;
  --btn-3-color: #fceeee;
  --btn-3-bg-active: var(--bg-main);
  --btn-3-color-active: var(--btn-1-color);
  --btn-5-disabled: #fac2b9;
  --bubble: #fff;
  --chat-border: #ffdbdb;
  --scroll-rgb: 255 149 156;

  --achromat-1: #282020;
  --achromat-2: #545454;
  --achromat-3: #888888;
  --achromat-4: #e0e0e0;
  --achromat-5: #ffffff;
  --achromat-0: #f9f9f9;

  --color-1: #b92730;
  --color-2: #f05d66;
  --color-2-15: rgba(240, 93, 102, 0.15);
  --color-3: #de4444;
  --color-4: #c55656;
  --color-5: #fbd9d6;
  --green: #27ae60;

  --user-nickname: #313033;
  --divider: #ea4545;
}

:root[data-theme="dark"] {
  --total: #000;
  --total-invert: #fff;
  --bg-main: #05001c;
  --bg-2: #322054;
  --bg-3: #1f1f1f;
  --bg-4: #121212;
  --bg-5: rgba(31, 32, 48, 0.8);
  --font-color: rgba(255, 255, 255, 0.7);
  --accent: #5736db;
  --accent-br: #5736db;
  --hr-color: #2b2831;
  --gray: #727272;
  --light-gray: #1e1e1e;
  --search-btn: var(--font-color);
  --btn-1-bg: #5736db;
  --btn-1-color: #faf2ff;
  --btn-2-bg: #1c1c2a;
  --btn-2-border: #1c1c2a;
  --btn-2-color: #faf2ff;
  --btn-3-bg: transparent;
  --btn-3-color: #faf2ff;
  --btn-3-bg-active: var(--accent);
  --btn-3-color-active: #faf2ff;
  --btn-5-disabled: #261f40;
  --bubble: #25232a;
  --chat-border: #303030;
  --scroll-rgb: 255 255 255;

  --achromat-5: #282020;
  --achromat-4: #545454;
  --achromat-3: #888888;
  --achromat-2: #e0e0e0;
  --achromat-1: #ffffff;
  --achromat-0: #1a1a1a;

  --color-1: #8e72ff;
  --color-2: #5736db;
  --color-2-15: rgba(87, 54, 219, 0.15);
  --color-3: #6640ff;
  --color-4: #745dd2;
  --color-5: #1a103f;
  --green: #27ae60;

  --user-nickname: #faf2ff;
  --divider: #121212;
}

// alert colors
$accent: #bf3535;
$green: #52ff82;

// GENERAL
.c-pointer {
  cursor: pointer;
}

/* mixins */
@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex($dir: row, $x: flex-start, $y: center, $wrap: nowrap) {
  display: flex;
  flex-direction: $dir;
  justify-content: $x;
  align-items: $y;
  flex-wrap: $wrap;
}

@mixin no-appearance {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  outline: none;
}

@mixin box {
  padding: 2em;
  background-color: var(--total);
  border: 1px solid var(--achromat-4);
  border-radius: 1.3em;

  @media (max-width: 1199px) {
    padding: 1em;
  }
}

@mixin list {
  list-style: none;
  padding-left: 0px;
}

@mixin clamp($count, $line, $strict: false) {
  display: -webkit-box;
  -webkit-line-clamp: $count;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: $line;

  @if $strict {
    height: $line * $count;
  } @else {
    max-height: $line * $count;
  }
}

html {
  scrollbar-color: rgba(var(--scroll-rgb), 0.75);
  scrollbar-width: thin;
}

::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  background-color: transparent;

  &:hover,
  &:active {
    background: transparent;
  }
}

::-webkit-scrollbar-thumb {
  background-color: rgba(var(--scroll-rgb), 0.5);
  border-radius: 3px;
  transition: var(--transition);

  &:hover {
    background-color: rgba(var(--scroll-rgb), 0.75);
  }

  &:active {
    background-color: rgba(var(--scroll-rgb), 1);
  }
}

::-webkit-scrollbar-button:vertical:start:decrement,
::-webkit-scrollbar-button:vertical:end:increment,
::-webkit-scrollbar-button:horizontal:start:decrement,
::-webkit-scrollbar-button:horizontal:end:increment {
  display: none;
}

a,
a:hover,
a:focus,
a:active {
  color: inherit;
  outline: none;
  text-decoration: none;
  cursor: pointer;
}

p,
label,
span,
a,
form,
fieldset,
legend,
select,
div,
blockquote,
cite,
figure,
figcaption,
table,
ul,
ol,
dl,
li {
  line-height: 150%;
  margin-bottom: 0px;
}

input,
textarea,
select,
.phone-input {
  border: 1px solid var(--achromat-4);
  background-color: var(--total);
  color: var(--achromat-2);
  border-radius: 10px;
  padding: 1em 1.5em;
  width: 100%;
  line-height: 120%;
  outline: none;
  display: block;
  transition: var(--transition);

  &:-moz-placeholder,
  &::-moz-placeholder,
  &::-webkit-input-placeholder,
  &:-ms-input-placeholder,
  &::-ms-input-placeholder {
    color: var(--achromat-4);
    font-weight: 400;
  }

  &::placeholder {
    color: var(--achromat-4);
    font-weight: 400;
  }
}

input,
.phone-input {
  &[type="number"] {
    @include no-appearance();
    width: 100px;
    padding: 1em 0.5em;
    max-width: 100%;
  }

  &[type="radio"] {
    @include no-appearance();
    width: 1.5em;
    height: 1.5em;
    border: 2px solid var(--achromat-4);
    border-radius: 50%;
    box-shadow: none;
    padding: 0px;
    margin: 0px;
    transition: border var(--transition);
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 75%;
      height: 75%;
      background-color: var(--achromat-4);
      border-radius: 50%;
      transform: translate(-50%, -50%);
      transition: background-color var(--transition);
    }

    &:checked {
      border: 2px solid var(--color-2);

      &::before {
        background-color: var(--color-2);
      }
    }
  }

  &[type="checkbox"] {
    @include no-appearance();
    width: 1.5em;
    height: 1.5em;
    border: 1px solid var(--achromat-4);
    border-radius: 2px;
    box-shadow: none;
    padding: 0px;
    margin: 0px;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 75%;
      height: 40%;
      border-color: transparent;
      border-width: 0px 0px 3px 3px;
      border-style: solid;
      transform: translate(-50%, -65%) rotate(-45deg);
      transition: border-color var(--transition);
    }

    &:checked {
      &::before {
        border-color: var(--color-2);
      }
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button,
.phone-input::-webkit-outer-spin-button,
.phone-input::-webkit-inner-spin-button {
  @include no-appearance();
}

.phone-input:focus,
.form-control:focus {
  background: var(--total);
  color: var(--font-color);
  border-color: var(--achromat-4);
  box-shadow: none;
}

.input-rating {
  input {
    display: none;
  }

  .stars {
    display: flex;
    flex-direction: row-reverse;
    width: fit-content;

    button {
      display: flex;
      color: var(--color-5);

      &:not(:last-child) {
        margin-left: 0.25em;
      }

      svg {
        transition: var(--transition);
      }

      &:hover,
      &.active,
      &.active ~ button,
      &:hover,
      &:hover ~ button {
        color: var(--color-2);
      }
    }
  }
}

.password {
  position: relative;

  input {
    position: relative;
    z-index: 1;
  }

  button {
    position: absolute;
    top: 0px;
    right: 0px;
    height: 100%;
    z-index: 2;
    width: 2.5em;
    font-size: 1.5em;
    color: var(--achromat-3);

    &:hover,
    &:focus {
      color: var(--color-2);
    }

    &:active {
      color: var(--color-1);
    }
  }
}

select {
  @include no-appearance();
  padding: 1em 1.5em 1em 0.5em;
  background: url(../images/icons/chevron-down.svg) no-repeat;
  background-position: calc(100% - 1em) 50%;
  background-size: 0.5em;

  option {
    background-color: var(--achromat-0);
    color: var(--achromat-2);

    &[disabled] {
      color: var(--achromat-4);
    }

    &[selected] {
      background-color: var(--color-5);
      color: var(--color-1);
    }

    &:hover {
      background-color: var(--color-2) !important;
      color: var(--total) !important;
    }
  }
}

label {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  span {
    flex: 1;
    margin-left: 0.6em;
  }

  &.switch {
    input {
      border-radius: 12px;
      background-color: var(--achromat-4);
      width: 40px;
      height: 24px;
      position: relative;
      border: none;
      transition: var(--transition);
      cursor: pointer;

      &::before {
        content: unset;
      }

      &::after {
        content: "";
        position: absolute;
        top: 2px;
        left: 2px;
        width: 20px;
        height: 20px;
        background-color: var(--total);
        border-radius: 50%;
        transition: var(--transition);
      }

      &:checked {
        background-color: var(--green);

        &::after {
          left: 18px;
        }
      }
    }
  }
}

.form-search {
  position: relative;
  width: 250px;

  input {
    padding: 0.75em 1.5em;
    width: 100%;
    border: none;
    border-radius: 2em;
    background: var(--bg-4);
    color: var(--font-color);
    position: relative;
    z-index: 1;
    padding-right: 3em;

    &::placeholder {
      color: var(--search-btn) !important;
    }
  }

  button {
    position: absolute;
    top: 0%;
    right: 1.5em;
    height: 100%;
    z-index: 2;
    width: 3em;
    font-size: 1.2em;
    color: var(--search-btn);
    @include flex-center();
    transition: var(--transition);
  }

  div {
    position: absolute;
    top: 0%;
    right: 0%;
    height: 100%;
    z-index: 2;
    width: 2em;
    font-size: 1.2em;
    color: var(--search-btn);
    display: flex;
    align-items: center;
    transition: var(--transition);
  }
}

.chat-search {
  position: relative;

  input {
    width: auto;
  }

  div {
    position: absolute;
    top: 0%;
    right: 0%;
    height: 100%;
    z-index: 2;
    width: 2em;
    font-size: 1.2em;
    color: var(--search-btn);
    display: flex;
    align-items: center;
    transition: var(--transition);
  }
}

.form-search-2 {
  position: relative;
  flex: 1;

  input {
    position: relative;
    z-index: 1;
  }

  button {
    position: absolute;
    top: 0%;
    right: 0%;
    height: 100%;
    z-index: 2;
    width: 3em;
    font-size: 1.2em;
    color: var(--achromat-3);
    @include flex-center();
    transition: var(--transition);

    &:hover {
      color: var(--color-1);
    }

    &:active {
      color: var(--color-2);
    }
  }
}

.react-select {
  &__control {
    border-radius: 10px !important;
    border: 1px solid var(--achromat-4) !important;
    background-color: var(--total) !important;
    transition: var(--transition);

    &--is-focused {
      border: 1px solid var(--color-3) !important;
      box-shadow: none !important;
    }
  }

  &__value-container {
    padding: 0.75em 0em 0.75em 0.85em !important;
  }

  &__single-value {
    color: var(--achromat-1) !important;
  }

  &__indicator {
    color: var(--achromat-3) !important;
    transition: var(--transition);
    cursor: pointer;

    &:hover {
      color: var(--color-3) !important;
    }
  }

  &__indicator-separator {
    display: none;
  }

  &__menu {
    font-size: 0.9em;
    min-width: 150px;
    background-color: var(--total) !important;
    border: 1px solid var(--achromat-4) !important;
    border-radius: 10px !important;
    box-shadow: none !important;
    outline: none !important;
  }

  &__option {
    transition: var(--transition);

    &:hover,
    &:focus,
    &:active,
    &--is-focused {
      background-color: var(--achromat-4) !important;
      color: var(--achromat-2);
    }

    &--is-selected {
      background-color: var(--color-3) !important;
      color: #fff;
    }
  }

  &__input-container {
    margin: 0px !important;
    padding: 0px !important;
  }
}

fieldset {
  legend {
    font-size: 1.3em;
    color: var(--achromat-1);
    margin-bottom: 1em;
  }
}

.input-group {
  display: flex;
  flex-wrap: nowrap;
}

.input-file {
  @include flex-center();
  position: relative;
  min-width: 3em;
  height: 3em;
  color: var(--achromat-2);
  transition: var(--transition);
  cursor: pointer;

  &:hover {
    color: var(--color-1);
  }

  input {
    position: absolute;
    z-index: -1;
    opacity: 0;
    width: 1px;
    height: 1px;
    top: 0px;
    left: 0px;
    padding: 0px;
  }

  svg {
    font-size: 1.5em;
  }

  .ind {
    position: absolute;
    bottom: 0px;
    left: 0px;
    background-color: var(--color-1);
    color: var(--total);
    font-size: 0.8em;
    width: 1.4em;
    height: 1.4em;
    font-weight: 500;
    @include flex-center();
    border-radius: 50%;

    &:empty {
      display: none;
    }
  }
}

button {
  background: none;
  border: none;
  padding: unset;
  display: block;
  font-family: inherit;
  outline: none;
  color: inherit;
  cursor: pointer;
}

.btn {
  @include flex-center;
  padding: 1em 1.5em;
  text-align: center;
  transition: var(--transition);
  width: fit-content;
  font-size: 1em;
  line-height: 120%;
  font-weight: 400;
  border-radius: 2em;

  // .btn-1
  &-1 {
    @extend .btn;
    background: var(--btn-1-bg);
    color: var(--btn-1-color);
    border: none;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.02), 0px 2px 4px rgba(0, 0, 0, 0.08);

    &:hover,
    &:focus,
    &:active,
    &.active {
      color: var(--btn-1-bg);
      background: var(--btn-1-color);
    }

    &:active {
      opacity: 0.9;
    }

    &:disabled {
      background-color: var(--btn-5-disabled);
      color: gray;
      cursor: default;
    }
  }

  // .btn-2
  &-2 {
    @extend .btn;
    background: var(--btn-2-bg);
    color: var(--btn-2-color);
    border: 1px solid var(--btn-2-border);

    &:hover,
    &:focus,
    &:active,
    &.active {
      background: var(--accent);
      border: 1px solid var(--accent-br);
      color: var(--font-light);
    }

    &:disabled {
      background-color: var(--btn-5-disabled);
      color: gray;
      cursor: default;
    }
  }

  // .btn-3
  &-3 {
    @extend .btn;
    background: var(--btn-3-bg);
    color: var(--btn-3-color);

    &:hover,
    &:focus,
    &:active,
    &.active {
      background: var(--btn-3-bg-active);
      color: var(--btn-3-color-active);
    }

    &:disabled {
      background-color: var(--btn-5-disabled);
      color: gray;
      cursor: default;
    }
  }

  // .btn-4
  &-4 {
    @extend .btn;
    border: 1px solid var(--btn-2-border);
    color: var(--font-color);
    background-color: var(--bg-main);

    &:hover,
    &:focus,
    &:active,
    &.active {
      background: var(--accent);
      color: var(--font-light);
      border: 1px solid var(--accent-br);
    }

    &:disabled {
      background-color: var(--btn-5-disabled);
      color: gray;
      cursor: default;
    }
  }

  // .btn-5
  &-5 {
    @extend .btn;
    color: var(--bg-main);
    background-color: var(--color-3);

    &:hover,
    &:focus,
    &:active,
    &.active {
      background-color: var(--color-1);
      color: var(--bg-main);
    }

    &:disabled {
      background-color: var(--btn-5-disabled);
      color: gray;
      cursor: default;
    }
  }

  // .btn-6
  &-6 {
    @extend .btn;
    color: var(--color-1);
    background-color: var(--color-5);

    &:hover,
    &:focus,
    &:active,
    &.active {
      background-color: var(--color-1);
      color: var(--color-5);
    }

    &:disabled {
      background-color: var(--btn-5-disabled);
      color: gray;
      cursor: default;
    }
  }

  // .btn-7
  &-7 {
    @extend .btn;
    color: var(--achromat-2);
    border: 1px solid var(--achromat-4);
    background-color: var(--achromat-0);
    padding: 0.75em 2em;
    border-radius: 3em;

    &:hover,
    &:focus,
    &:active,
    &.active {
      background-color: var(--achromat-0);
      color: var(--color-1);
      border: 1px solid var(--color-1);
    }
  }

  // .btn-simple
  &-simple {
    border: none;
    padding: 0.25em 0.5em;
    border-radius: 10px;
    background-color: transparent;
    color: var(--achromat-2);

    &::after {
      content: unset;
    }

    &:hover,
    &:focus,
    &:active,
    &.active {
      background-color: var(--color-4);
      color: var(--achromat-5);
    }
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--achromat-1);
}

h1,
h2 {
  font-size: 2.5em;
  font-weight: 700;
  text-align: left;
  margin-bottom: 1em;

  @media (max-width: 767px) {
    font-size: 2em;
  }
}

h3 {
  font-size: 1.3em;
  font-weight: 500;
  text-align: left;
  margin-bottom: 0px;
  color: var(--font-color);
}

h4 {
  font-size: 1.5em;
  font-weight: 500;
  text-align: left;
  margin-bottom: 1em;
}

h5 {
  font-size: 1.1em;
  font-weight: 500;
  margin-bottom: 1em;
}

h6 {
  font-size: 1.3em;
  font-weight: 400;
  text-align: left;
  margin-bottom: 1em;
}

.fs {
  &-08 {
    font-size: 0.8em;
  }

  &-09 {
    font-size: 0.9em;
  }

  &-11 {
    font-size: 1.1em;
  }

  &-12 {
    font-size: 1.2em;
  }

  &-13 {
    font-size: 1.3em;
  }

  &-14 {
    font-size: 1.4em;
  }

  &-15 {
    font-size: 1.5em;
  }

  &-16 {
    font-size: 1.6em;
  }

  &-17 {
    font-size: 1.7em;
  }

  &-18 {
    font-size: 1.8em;
  }

  &-19 {
    font-size: 1.9em;
  }

  &-20 {
    font-size: 2em;
  }

  &-25 {
    font-size: 2.5em;
  }

  &-30 {
    font-size: 3em;
  }

  &-35 {
    font-size: 3.5em;
  }

  &-40 {
    font-size: 4em;
  }
}

.fw {
  &-3 {
    font-weight: 300;
  }

  &-4 {
    font-weight: 400;
  }

  &-5 {
    font-weight: 500;
  }

  &-6 {
    font-weight: 600;
  }

  &-7 {
    font-weight: 700;
  }

  &-8 {
    font-weight: 800;
  }

  &-9 {
    font-weight: 900;
  }
}

.lh-1 {
  line-height: 100%;
}

.color-1 {
  color: var(--color-1);
}

.color-2 {
  color: var(--color-2);
}

.color-3 {
  color: var(--color-3);
}

.color-4 {
  color: var(--color-4);
}

.color-5 {
  color: var(--color-5);
}

.achromat-1 {
  color: var(--achromat-1);
}

.achromat-2 {
  color: var(--achromat-2);
}

.achromat-3 {
  color: var(--achromat-3);
}

.achromat-4 {
  color: var(--achromat-4);
}

.accent {
  color: var(--accent-br);
}

.total-invert {
  color: var(--total-invert);
}

hr {
  display: block;
  opacity: 1;
  border: none;
  margin: 0px;

  &.vertical {
    @extend hr;
    width: 1px;
    height: 40px;
    background-color: var(--bg-4);
  }

  &.horizontal {
    @extend hr;
    width: 100%;
    height: 1px;
    background-color: var(--hr-color);
  }
}

.flex-1 {
  flex: 1;
}

@media (max-width: 575px) {
  .w-xs-100 {
    width: 100%;
  }
}

.ws-no {
  white-space: nowrap;
}

.mt-6 {
  margin-top: 6em;

  @media (max-width: 767px) {
    margin-top: 5em;
  }

  @media (max-width: 575px) {
    margin-top: 4em;
  }
}

.mb-6 {
  margin-bottom: 6em;

  @media (max-width: 991px) {
    margin-bottom: 4em;
  }

  @media (max-width: 767px) {
    margin-bottom: 3em;
  }

  @media (max-width: 575px) {
    margin-bottom: 2em;
  }
}

@media (max-width: 767px) {
  .px-mobile-0 {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.star-rating {
  @include flex-center();
  color: var(--color-2);

  svg {
    font-size: 1.5em;

    &:not(:first-child) {
      margin-left: 0.25em;
    }

    @media (max-width: 1199px) {
      font-size: 1.35em;
    }
  }
}

.pagination {
  width: 100%;

  ul {
    width: 100%;
    list-style: none;
    padding-left: 0px;
    @include flex-center();

    li {
      &:not(:first-child) {
        margin-left: 0.5em;
      }

      &.ellipsis {
        width: 2.6em;
        height: 2.6em;
        @include flex-center();
      }

      a,
      button {
        @include flex-center();
        color: var(--achromat-2);
        width: 2.6em;
        height: 2.6em;
        border: 1px solid var(--achromat-4);
        border-radius: 50%;

        &:hover {
          color: var(--color-3);
          border: 1px solid var(--color-3);
        }

        &.active {
          color: var(--color-1);
          border: 1px solid var(--color-1);
        }
      }
    }
  }
}

.table {
  --bs-table-striped-bg: var(--achromat-0);

  @media (max-width: 1199px) {
    font-size: 13px;
  }

  tr.centered {
    td {
      vertical-align: middle;
    }
  }

  th {
    font-size: 1.3em;
    font-weight: 400;
    color: var(--achromat-1);
    vertical-align: middle;

    @media (max-width: 575px) {
      font-size: 1em;
      font-weight: 700;
    }

    div {
      font-size: 0.8em;
      color: var(--achromat-3);
    }
  }

  td {
    font-size: 1em;
    color: var(--achromat-2) !important;
  }
}

.dropdown {
  &-menu {
    border-radius: 1em;
    background-color: var(--achromat-5);
    border: 1px solid var(--achromat-4);
  }

  &-item {
    color: var(--achromat-2);
    @include flex-center();
    justify-content: flex-start;
    background-color: transparent;
    transition: var(--transition);
    padding: 0.5em 1em;

    &:hover,
    &:focus {
      background-color: var(--achromat-4);
      color: var(--achromat-2);
    }

    &:active {
      background-color: var(--color-1);
      color: var(--achromat-5);
    }

    svg {
      font-size: 1.3em;
      margin-right: 0.2em;
    }
  }
}

.accordion {
  --bs-accordion-color: var(--achromat-2);
  --bs-accordion-bg: var(--achromat-5);
  --bs-accordion-border-color: var(--achromat-4);
  --bs-accordion-border-radius: 0px;
  --bs-accordion-inner-border-radius: 0px;
  --bs-accordion-active-color: var(--color-1);
  --bs-accordion-active-bg: var(--color-5);
  --bs-accordion-btn-color: var(--achromat-1);
  --bs-accordion-btn-focus-border-color: var(--color-2);
  --bs-accordion-btn-focus-box-shadow: unset;
  --bs-accordion-btn-icon: unset;
  --bs-accordion-btn-active-icon: unset;

  &-header {
    font-size: 1em;
  }

  &-button {
    font-size: 1em;
    font-weight: 400;

    svg {
      font-size: 1.35em;
    }

    span {
      flex: 1;
    }

    .chevron {
      margin-left: auto;
      transform: rotate(0deg);
      transition: var(--transition);
    }

    &:not(.collapsed) {
      font-weight: 700;

      .chevron {
        transform: rotate(180deg);
      }
    }

    &::after {
      content: unset;
    }
  }
}

.tabs-group {
  display: flex;
  border-bottom: 1px solid var(--achromat-4);

  button {
    padding: 0.5em 0;
    font-size: 1.3em;
    color: var(--achromat-3);
    border-bottom: 4px solid transparent;
    margin-bottom: -2px;

    @media (max-width: 1199px) {
      font-size: 1.2em;
    }

    @media (max-width: 991px) {
      font-size: 1.1em;
    }

    @media (max-width: 767px) {
      font-size: 1em;
    }

    @media (max-width: 575px) {
      font-size: 0.8em;
    }

    &:not(:first-child) {
      margin-left: 2em;
    }

    &.active {
      color: var(--achromat-1);
      border-bottom: 4px solid var(--color-1);
    }
  }
}

.box {
  background-color: var(--achromat-0);
  border: 1px solid var(--achromat-4);
  border-radius: 10px;
  padding: 1em;
}

body {
  margin: 0;
  font-family: "Ubuntu";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 15px;
  font-weight: 400;
  color: var(--achromat-2);
  background-color: var(--bg-main);
  transition: var(--transition);

  @media (max-width: 991px) {
    font-size: 14px;
  }

  @media (max-width: 767px) {
    padding-bottom: var(--f-height);
  }
}

header {
  position: relative;
  z-index: 1050;
  height: var(--h-height);
  background-color: var(--bg-2);
  color: var(--font-light);
  transition: var(--transition);

  .container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .menu {
      font-size: 2em;
      color: var(--font-light);
      transform: rotateY(180deg);
    }

    hr.vertical {
      background-color: var(--divider);
    }
  }

  &.fav-visible {
    pointer-events: none;
    filter: grayscale(0.3);
  }

  .search-results-overlay {
    content: "";
    position: fixed;
    z-index: -1;
    background: black;
    opacity: 0.5;
    inset: 0;
    width: 100vw;
    height: 100vh;
  }

  .search-results-container {
    color: var(--achromat-2);
    background-color: var(--bg-main);
    transition: var(--transition);
    height: min-content;
    border-radius: 0 0 1.5em 1.5em;
    padding: 2em;

    & > div {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      gap: 2em;

      & > div {
        padding: 1em 0;
      }
    }

    // &::before {
    //     content: '';
    //     position: fixed;
    //     z-index: -1;
    //     background: black;
    //     opacity: 0.5;
    //     inset: 0;
    //     width: 100vw;
    //     height: 100vh;
    // }

    &::after {
      content: "";
      position: absolute;
      background-color: var(--bg-2);
      inset: 0;
      height: var(--h-height);
      z-index: -1;
    }
  }
}

.notifications {
  margin-left: 1em;
  position: relative;
  height: var(--h-height);

  &-btn {
    height: 100%;
    @include flex-center();
    transition: var(--transition);
    color: #fff;

    &:hover {
      color: var(--color-1);
    }

    svg {
      font-size: 1.34em;
      stroke-width: 2.5px;
    }

    span {
      font-size: 0.8em;
      margin-left: 0.5em;
    }
  }

  &-box {
    background-color: transparent !important;
    border: none !important;

    .offcanvas-body {
      background-color: var(--achromat-5);
      color: var(--total-invert);
      width: 544px;
      margin-left: auto;
      border-radius: 0px 0px 1.34em 1.34em;
      padding-left: 2em !important;
      padding-right: 2em !important;
      padding-bottom: 1em !important;

      @media (max-width: 767px) {
        width: 400px;
      }

      @media (max-width: 575px) {
        width: 300px;
        padding-left: 1em !important;
        padding-right: 1em !important;
        padding-bottom: 1em !important;
      }
    }

    ul {
      @include list();

      li {
        border-bottom: 1px solid var(--gray);

        a {
          width: 100%;
          @include flex(row, flex-start, center, nowrap);
          transition: var(--transition);
          padding: 1em;

          @media (max-width: 575px) {
            padding: 0.75em;
          }

          div {
            flex: 1;
          }

          svg {
            color: var(--achromat-3);
            font-size: 0.8em;
            transition: var(--transition);
          }

          &:hover,
          &:focus,
          &:active {
            color: var(--color-1);

            svg {
              color: var(--color-1);
            }
          }
        }
      }
    }

    .more {
      color: var(--achromat-3);
      @include flex(column, center, center);
      margin: 1em auto 0px;

      &:hover,
      &:focus,
      &:active {
        color: var(--color-2);
      }
    }
  }
}

.themes-switcher {
  position: relative;

  @media (max-width: 575px) {
    margin-right: 1em;
  }

  input {
    @include no-appearance();
    display: block;
    width: 2em;
    height: 1em;
    border-radius: 1em;
    border: none !important;
    padding: 0;
    cursor: pointer;
    //light theme
    background-color: rgba(0, 0, 0, 0.15);

    &::before {
      content: "";
      position: absolute;
      top: 0;
      height: 100%;
      width: 50%;
      background-repeat: no-repeat;
      background-size: 1em;
      //transition: all var(--transition);
      border: none !important;
      //light theme
      left: 0%;
      background-image: url(../images/icons/moon.svg);
      transform: scale(1.5) translateX(-25%);
    }

    //dark theme
    &:checked {
      background-color: rgba(0, 0, 0, 0.5);

      &::before {
        left: 100%;
        background-image: url(../images/icons/sun.svg);
        transform: scale(1.5) translateX(-50%);
      }
    }
  }
}

.fav {
  &-btn {
    font-size: 1.7em;
    color: var(--accent);
  }

  &-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;

    button {
      color: var(--gray);
      margin-left: 0.35em;
      font-size: 1.4em;
      transition: var(--transition);

      &:hover,
      &:focus,
      &:active {
        color: var(--search-btn);
      }
    }

    ul {
      list-style: none;
      padding-left: 0px;
      display: flex;
      flex-wrap: wrap;

      li {
        border: 1px solid var(--hr-color);
        color: var(--font-color);
        background-color: transparent;
        font-weight: 500;
        @include flex-center();
        padding: 0.25em 0.5em;
        border-radius: 1em;
        transition: var(--transition);

        &:not(:first-child) {
          margin-left: 0.5em;
        }

        &:hover,
        &:focus,
        &:active {
          background-color: var(--total);
        }

        img {
          width: 1.35em;
          height: 1.35em;
          border-radius: 50%;
          object-fit: cover;
          margin-right: 0.5em;
        }
      }
    }
  }
}

main {
  min-height: calc(100vh - var(--h-height) - var(--f-height));
  padding-top: 2em;
  padding-bottom: 1em;

  @media (max-width: 767px) {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}

//game cards
.game {
  @include flex-center();
  position: relative;
  width: 100%;

  &-small {
    @extend .game;

    img {
      width: 50px;
      height: 50px;
      border-radius: 1em;
      object-fit: cover;
    }

    figcaption {
      flex: 1;
      font-size: 1em;
      margin-left: 1em;
      font-weight: 500;
    }
  }

  &-midi {
    @extend .game;

    @media (max-width: 575px) {
      flex-direction: column;
      align-items: flex-start;
    }

    img {
      width: 50px;
      height: 50px;
      border-radius: 1em;
      object-fit: cover;

      @media (max-width: 575px) {
        width: 45px;
        height: 45px;
      }
    }

    figcaption {
      flex: 1;
      font-size: 1.1em;
      margin-left: 1em;
      font-weight: 700;

      @media (max-width: 575px) {
        margin-left: 0px;
        margin-top: 0.6em;
      }
    }
  }
}

.game-large {
  width: 100%;
  height: 600px;
  position: relative;
  border-radius: 1.25em;
  overflow: hidden;
  z-index: 1;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  mask-image: -webkit-radial-gradient(white, black);
  isolation: isolate;
  transform: translateZ(0);

  @media (max-width: 1399px) {
    height: 550px;
  }

  @media (max-width: 1199px) {
    height: 470px;
  }

  @media (max-width: 767px) {
    border-radius: 0px;
    height: 700px;
  }

  @media (max-width: 575px) {
    height: calc(100vh - var(--h-height));
  }

  img {
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 2;
    transform: scale(1);
    transition: 600ms ease;
    border-radius: 1.25em;
  }

  &:hover {
    img {
      transform: scale(1.025);
    }
  }

  figcaption {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to bottom,
      transparent 0%,
      rgba(0, 0, 0, 0.2) 40%,
      rgba(0, 0, 0, 0.6) 60%,
      #000000 100%
    );
    display: flex;
    align-items: flex-end;
    justify-content: stretch;
    padding: 3em;
    border-radius: 1.25em;

    @media (max-width: 1199px) {
      padding: 2em;
    }

    h2 {
      font-size: 3em;

      @media (max-width: 1199px) {
        font-size: 2.5em;
      }
    }

    h2,
    p {
      color: var(--font-light);
    }
  }
}

//sliders
.swiper-fade {
  .swiper-slide {
    opacity: 0 !important;

    &-visible {
      opacity: 1 !important;
    }
  }
}

.main-slider {
  @include flex-center();

  .mainslides {
    flex: 1;
    margin-right: 2.5em;

    @media (max-width: 1199px) {
      margin-right: 1.5em;
    }

    @media (max-width: 991px) {
      margin-right: 0px;
    }

    .swiper-button-next,
    .swiper-button-prev {
      // background-color: var(--bg-3);
      // color: var(--font-color);
      font-size: 1.5em;
      width: 2em;
      height: 2em;
      border-radius: 2em;
      padding: 0px !important;
      @include flex-center();
      bottom: 2em;
      top: unset;
      left: unset;
      margin-top: 0px;

      @media (max-width: 1199px) {
        bottom: 1em;
      }

      @media (max-width: 767px) {
        top: 1em;
        bottom: unset;
      }

      &::after {
        content: unset;
      }
    }

    .swiper-button-next {
      right: 2em;

      @media (max-width: 1199px) {
        right: 1em;
      }
    }

    .swiper-button-prev {
      right: 5em;

      @media (max-width: 1199px) {
        right: 3.5em;
      }
    }

    .swiper-button-disabled {
      opacity: 0.5;
    }
  }

  .thumbslides {
    width: 200px;
    height: 550px;

    @media (max-width: 1399px) {
      height: 500px;
    }

    @media (max-width: 1199px) {
      height: 420px;
    }

    @media (max-width: 991px) {
      display: none;
    }

    .swiper-slide {
      @include flex-center();
      cursor: pointer;
    }
  }
}

[data-theme="light"] .main-slider {
  .thumbslides .swiper-slide {
    opacity: 0.8;

    &-thumb-active {
      opacity: 1;
    }
  }
}

[data-theme="dark"] .main-slider {
  .thumbslides .swiper-slide {
    opacity: 0.6;

    &-thumb-active {
      opacity: 1;
    }
  }
}

.sort {
  display: flex;
  align-items: stretch;
  background-color: var(--bg-2);
  padding: 0.5em;
  border-radius: 1.5em;
  overflow: hidden;
  margin-bottom: 48px;

  .swiper {
    width: 100%;

    &-slide {
      width: fit-content;
    }
  }

  ul {
    --line: 2.5em;
    --gap: 0.5em;
    list-style: none;
    padding-left: 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    flex: 1;
    overflow: hidden;
    max-height: var(--line);
    transition: max-height var(--transition);

    @media (max-width: 767px) {
      flex-wrap: nowrap;
      overflow: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }

      &::-webkit-scrollbar-thumb {
        display: none;
      }

      &::-webkit-scrollbar-button:vertical:start:decrement,
      &::-webkit-scrollbar-button:vertical:end:increment,
      &::-webkit-scrollbar-button:horizontal:start:decrement,
      &::-webkit-scrollbar-button:horizontal:end:increment {
        display: none;
      }
    }

    &.full {
      max-height: calc(var(--line) * 4 + var(--gap) * 3);

      @media (max-width: 1199px) {
        max-height: calc(var(--line) * 5 + var(--gap) * 4);
      }

      @media (max-width: 991px) {
        max-height: calc(var(--line) * 6 + var(--gap) * 5);
      }

      @media (max-width: 767px) {
        max-height: calc(var(--line) * 7 + var(--gap) * 6);
      }
    }

    li {
      margin-bottom: var(--gap);

      @media (max-width: 767px) {
        margin-bottom: 0px;
      }

      & > * {
        white-space: nowrap;
      }

      &:not(:first-child) {
        margin-left: var(--gap);
      }

      .btn {
        font-weight: 500;

        @media (max-width: 767px) {
          padding: 0.7em 1em;
        }
      }
    }
  }

  hr {
    height: unset;
  }

  &-more {
    font-size: 1.5em;
    width: 1.5em;
    height: 1.5em;
    color: #fff;
    align-self: center;
    transition: var(--transition);
  }
}

.fixed-sort {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  opacity: 0;
  transition: var(--transition);
  background-color: var(--bg-2);
  z-index: -1;

  &.show {
    opacity: 1;
    z-index: 1000;
  }

  .sort {
    background-color: transparent;
    margin-bottom: 0;
  }
}

.chat {
  background: var(--bg-2);
  border-radius: 1em;

  &-title {
    padding: 1.5em 2em;
    display: flex;
    align-items: baseline;
    justify-content: flex-start;
    color: var(--font-light);

    @media (max-width: 575px) {
      padding: 1em;
    }
  }

  &-window {
    background-color: var(--bg-3);
    padding: 2em;
    border-radius: 1em;
    border: 1px solid var(--chat-border);

    @media (max-width: 575px) {
      padding: 1em;
    }

    form {
      background-color: var(--bubble);
      margin-top: 2em;
      display: flex;
      align-items: stretch;
      padding: 1em;
      border-radius: 1em;

      @media (max-width: 575px) {
        margin-top: 1em;
        padding: 0.5em;
      }

      input {
        flex: 1;
        background-color: transparent;
      }

      hr {
        height: unset;
        background-color: var(--chat-border);
      }

      button {
        font-size: 1.5em;
        color: var(--accent-br);
        padding: 0.5em;
      }
    }
  }

  &-space {
    height: 570px;
    overflow-y: scroll;
  }

  &-box {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;

    &:not(:first-child) {
      margin-top: 1.6em;
    }

    &-user {
      width: 2.6em;
      margin-right: 1.6em;

      @media (max-width: 575px) {
        margin-right: 0.6em;
      }

      img {
        width: 100%;
        height: 2.6em;
        border-radius: 0.5em;
        object-fit: cover;
      }
    }

    &-messages {
      flex: 1;

      .bubble {
        background-color: var(--bubble);
        padding: 1em 1.5em;
        border-radius: 0 1em 1em 1em;

        &:not(:first-child) {
          margin-top: 1em;
        }

        font-size: 1em;
        font-weight: 300;

        .name {
          font-size: 1.1em;
          font-weight: 500;
        }

        time {
          font-size: 0.8em;
          font-weight: 300;
          opacity: 0.8;
        }
      }
    }
  }

  &-box-reverse {
    display: flex;
    flex-direction: row-reverse;
    align-items: stretch;
    justify-content: flex-start;

    &:not(:first-child) {
      margin-top: 1.6em;
    }

    &-user {
      width: 2.6em;
      margin-left: 1.6em;

      @media (max-width: 575px) {
        margin-left: 0.6em;
      }

      img {
        width: 100%;
        height: 2.6em;
        border-radius: 0.5em;
        object-fit: cover;
      }
    }

    &-messages {
      flex: 1;

      .bubble {
        background-color: var(--bubble);
        padding: 1em 1.5em;
        border-radius: 1em 0 1em 1em;

        &:not(:first-child) {
          margin-top: 1em;
        }

        font-size: 1em;
        font-weight: 300;

        .name {
          font-size: 1.1em;
          font-weight: 500;
        }

        time {
          font-size: 0.8em;
          font-weight: 300;
          opacity: 0.8;
        }
      }
    }
  }
}

/* NEWS */
.news {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 3em;

  img {
    width: 4.3em;
    height: 4.3em;
    object-fit: cover;
    border-radius: 1em;
    margin-right: 1.5em;
  }

  h5 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 1.3em;
    height: 2.6em;
  }

  p {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 1.3em;
    height: 3.9em;
    font-size: 0.95em;
  }

  &-midi {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    position: relative;

    @media (max-width: 575px) {
      flex-direction: column-reverse;
    }

    .text {
      flex: 1;

      h3 {
        margin-bottom: 1em;
      }

      p {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        line-height: 1.5em;
        max-height: 4.5em;
        margin-bottom: 1em;
      }

      time {
        display: block;
        color: var(--achromat-3);
      }

      a {
        @include flex-center();
        justify-content: flex-start;
        margin-top: 1em;
        color: var(--color-1);

        svg {
          transform: scale(1);
          transition: var(--transition);
          margin-left: 0.5em;
        }

        &:hover {
          svg {
            transform: scale(1.5);
            margin-left: 1.5em;
          }
        }
      }
    }

    img {
      width: 45%;
      height: 200px;
      object-fit: cover;
      border-radius: 2em;
      margin-left: 2em;

      @media (max-width: 575px) {
        margin-left: 0px;
        width: 100%;
        height: 50vw;
        margin-bottom: 1em;
      }
    }
  }

  &-all {
    .news-list {
      @include list();

      li:not(:last-child) {
        border-bottom: 1px solid var(--achromat-4);
        padding-bottom: 2em;
        margin-bottom: 2em;
      }
    }

    .box {
      padding: 2em;
      border-radius: 2em;
      border: none;
      box-shadow: 0px 4px 20px var(--achromat-4);

      @media (max-width: 575px) {
        padding: 1.5em;
        border-radius: 1.5em;
      }
    }
  }

  &-page {
    position: relative;

    .box {
      padding: 2em;
      border-radius: 2em;
      border: none;
      box-shadow: 0px 4px 20px var(--achromat-4);

      @media (max-width: 575px) {
        padding: 1.5em;
        border-radius: 1.5em;
      }
    }

    figure {
      position: relative;
      margin-bottom: 2em;

      img {
        position: relative;
        z-index: 1;
        width: 100%;
        height: 400px;
        object-fit: cover;
        filter: brightness(60%);
        border-radius: 2em;

        @media (max-width: 1399px) {
          height: 350px;
        }

        @media (max-width: 1199px) {
          height: 300px;
        }

        @media (max-width: 991px) {
          height: 250px;
        }

        @media (max-width: 767px) {
          height: 200px;
        }

        @media (max-width: 575px) {
          height: 40vw;
        }
      }

      figcaption {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        z-index: 2;
        color: var(--achromat-5);
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-left: 10%;

        h1 {
          color: white;
        }
      }
    }

    h3,
    p {
      margin-bottom: 1em;
    }
  }
}

.game-page {
  .main-img {
    width: 100%;
    border-radius: 1.5em;
  }

  p {
    margin-bottom: 1em;
  }

  .add-fav {
    text-align: right;
    color: var(--achromat-3);
    @include flex-center();
    transition: var(--transition);

    &.active {
      color: var(--color-1);
    }
  }

  select {
    padding-right: 2.5em;
  }
}

.lot-preview {
  border-top: 1px solid var(--achromat-4);
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background: var(--hr-color);
    cursor: pointer;
  }

  &-user {
    display: flex;
    align-items: flex-start;
    position: relative;
    overflow: hidden;

    // &:hover::after {
    //     content: 'Перейти на страницу пользователя';
    //     position: absolute;
    //     bottom: 0;
    //     color: var(--achromat-2);
    //     border: 1px solid var(--achromat-4);
    //     background-color: var(--achromat-0);
    //     padding: 0.1em 0.6em;
    //     border-radius: 0.3em;
    //     width: max-content;
    // }

    @media (max-width: 768px) {
      flex-direction: column;
    }

    .img {
      --size: 60px;
      position: relative;
      width: var(--size);
      margin-right: 1em;

      @media (max-width: 991px) {
        --size: 45px;
        margin-right: 0.5em;
      }

      @media (max-width: 575px) {
        --size: 30px;
        margin-right: 0.25em;
      }

      img {
        width: var(--size);
        height: var(--size);
        border-radius: 50%;
        object-fit: cover;
        position: relative;
        z-index: 1;
      }

      .indicator {
        position: absolute;
        z-index: 2;
        bottom: 0px;
        right: 0px;
        width: 1em;
        height: 1em;
        background-color: var(--achromat-4);
        border: 2px solid var(--bg-main);
        border-radius: 50%;

        &.online {
          background-color: var(--green);
        }

        @media (max-width: 575px) {
          width: 0.75em;
          height: 0.75em;
        }
      }
    }
  }
}

.lot-line {
  @include list();
  border-top: 1px solid var(--achromat-4);
  padding: 0.5em 0;
  display: grid;
  grid-template-columns: 100px 120px auto 300px 100px;
  grid-template-rows: auto;
  grid-template-areas: "server category description seller price";
  gap: 1em 1em;
  transition: var(--transition);

  @media (max-width: 1199px) {
    grid-template-columns: 100px 120px auto 250px 100px;
  }

  @media (max-width: 991px) {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "server price"
      "description description"
      "seller seller";
    border-top: none;
    border-bottom: 1px solid var(--achromat-4);
  }

  @media (max-width: 575px) {
    gap: 0.6em 0.6em;
  }

  &:hover {
    background: var(--hr-color);
    cursor: pointer;
  }

  &-server {
    grid-area: server;
  }

  &-cat {
    grid-area: category;

    @media (max-width: 991px) {
      display: none;
    }
  }

  &-descr {
    grid-area: description;

    .clamped {
      @include clamp(2, 1.2em, true);
    }
  }

  &-user {
    grid-area: seller;
    display: flex;
    align-items: flex-start;
    position: relative;
    overflow: hidden;

    .img {
      --size: 60px;
      position: relative;
      width: var(--size);
      margin-right: 1em;

      @media (max-width: 991px) {
        --size: 50px;
      }

      @media (max-width: 575px) {
        --size: 40px;
        margin-right: 0.5em;
      }

      img {
        width: var(--size);
        height: var(--size);
        border-radius: 50%;
        object-fit: cover;
        position: relative;
        z-index: 1;
      }

      .indicator {
        position: absolute;
        z-index: 2;
        bottom: 0px;
        right: 0px;
        width: 1em;
        height: 1em;
        background-color: var(--achromat-4);
        border: 2px solid var(--bg-main);
        border-radius: 50%;

        &.online {
          background-color: var(--green);
        }

        @media (max-width: 575px) {
          width: 0.75em;
          height: 0.75em;
        }
      }
    }
  }

  &-price {
    grid-area: price;
    color: var(--color-1);
    font-weight: 700;

    @media (max-width: 991px) {
      text-align: right;
    }
  }

  &-heading {
    @extend .lot-line;
    border-top: none;
    color: var(--achromat-1);

    & > div {
      font-size: 1.3em;
    }

    @media (max-width: 991px) {
      display: none;
    }

    &:hover {
      background: transparent;
      cursor: unset;
    }

    .lot-line-price {
      color: var(--achromat-1);
      font-weight: 400;
    }
  }
}

.lot-page {
  .message-window {
    border: 1px solid var(--achromat-4);
    border-radius: 1em;
    overflow: hidden;
    height: 820px;

    @media (max-width: 991px) {
      height: 600px;
    }

    .top {
      border-bottom: 1px solid var(--achromat-4);
    }

    .middle {
      max-height: unset;
    }
  }
}

.review-block {
  display: flex;
  margin-top: 2em;

  img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;

    @media (max-width: 575px) {
      width: 50px;
      height: 50px;
    }
  }
}

.user-page {
  .img {
    width: 100%;
    height: 280px;
    object-fit: cover;
    border-radius: 1.5em;
    margin-bottom: 1em;
    background-color: var(--bg-3);

    @media (max-width: 1399px) {
      height: 260px;
    }

    @media (max-width: 1199px) {
      height: 215px;
    }

    @media (max-width: 767px) {
      height: 200px;
    }

    @media (max-width: 575px) {
      height: 95vw;
    }
  }

  .num {
    font-size: 2em;
    font-weight: 700;
    line-height: 115%;
    color: var(--color-2);
    border-bottom: 4px solid currentColor;
    width: 2em;
    margin: 0 auto;
  }

  .info {
    font-size: 1.2em;

    @media (max-width: 575px) {
      font-size: 1em;
    }
  }

  .game-btn {
    @include flex();
    width: 100%;
    border: 1px solid var(--gray);
    border-radius: 1.3em;
    padding: 1.3em;
    transition: 300ms linear;
    color: var(--achromat-1);

    @media (max-width: 1399px) {
      border-radius: 1em;
      padding: 1em;
    }

    @media (max-width: 575px) {
      padding: 0.5em;
    }

    img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 1.3em;

      @media (max-width: 1399px) {
        margin-right: 0.5em;
      }

      @media (max-width: 575px) {
        width: 30px;
        height: 30px;
      }
    }

    h5 {
      color: inherit;
      margin-bottom: 0px;
    }

    &:hover,
    &:focus {
      background-color: var(--light-gray);
    }

    &:active,
    &.active {
      color: #fff;
      background-color: var(--color-2);
    }
  }

  table {
    th,
    td {
      padding: 1rem;

      @media (max-width: 575px) {
        padding: 0.75rem 0.35rem;
      }
    }

    tbody {
      tr {
        border-top: 1px solid var(--achromat-4);
      }
    }

    .stretched-link:hover::after {
      background-color: var(--color-2-15);
    }
  }
}

.account {
  --width-1: 140px;
  --width-2: 180px;

  @media (max-width: 1199px) {
    --width-1: 110px;
    --width-2: 150px;
  }

  @media (max-width: 991px) {
    padding: 2em 0;
  }

  @media (max-width: 767px) {
    --width-1: 70px;
    --width-2: 50px;
  }

  @media (max-width: 575px) {
    --width-1: 50px;
    --width-2: 30px;
  }

  nav.menu {
    @include box();

    @media (max-width: 991px) {
      @include flex-center();
    }

    ul {
      list-style: none;
      padding-left: 0px;

      li {
        a,
        button {
          padding: 1em 0;
          display: flex;
          align-items: center;
          justify-content: flex-start;

          @media (max-width: 1199px) {
            padding: 0.75em 0;
          }

          @media (max-width: 991px) {
            font-size: 1.3em;
          }

          span {
            font-size: 1.1em;
            margin-left: 1em;
            color: var(--achromat-1);

            @media (max-width: 1199px) {
              margin-left: 0.5em;
              font-size: 1em;
            }
          }

          svg {
            font-size: 1.6em;
            color: var(--color-2);

            @media (max-width: 1199px) {
              font-size: 1.4em;
            }
          }

          &.active {
            span {
              font-weight: 700;
              color: var(--color-1);
            }

            svg {
              color: var(--color-1);
            }
          }
        }
      }
    }
  }

  .main {
    @include box();
    // overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 100%;

    .table-responsive {
      height: 100%;
    }
  }

  .user-photo {
    display: block;
    margin: 0 auto 1.5em;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background-color: var(--achromat-4);
    overflow: hidden;
    position: relative;

    @media (max-width: 1199px) {
      margin: 0 1em 0 0;
    }

    @media (max-width: 991px) {
      width: 160px;
      height: 160px;
    }

    &:hover,
    &:focus {
      .btns {
        opacity: 1;
      }
    }

    .replacement {
      position: relative;
      z-index: 1;
      width: 110%;
      height: 110%;
      color: var(--achromat-5);
      margin-left: -5%;
      margin-top: -5%;
    }

    img {
      position: relative;
      z-index: 1;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .btns {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.45);
      z-index: 2;
      @include flex-center();
      flex-direction: column;
      opacity: 0;
      transition: var(--transition);

      button {
        @include flex-center();
        color: #fff;
      }
    }

    // .user-photo_upload
    &_upload {
      position: relative;
      z-index: inherit;

      input[type="file"] {
        width: 100%;
        height: 100%;
        opacity: 0;
        position: absolute;
        z-index: 3;
        cursor: pointer;
      }
    }
  }

  .cards-list {
    padding-left: 0px;
    list-style: none;
    display: flex;
    flex-wrap: wrap;

    .bank-card {
      border: 1px solid var(--color-5);
      border-radius: 20px;
      padding: 1em;
      width: 200px;
      height: 140px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-right: 1.6em;
      transition: var(--transition);
      margin-bottom: 1em;

      @media (max-width: 767px) {
        width: 160px;
        height: 115px;
      }

      @media (max-width: 575px) {
        margin-right: 1em;
        width: 150px;
        height: 110px;
      }

      &:hover,
      &:focus {
        border: 1px solid var(--color-2);
        cursor: pointer;
      }

      &.active {
        border: 1px solid var(--color-1);
      }

      .info {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .bank-img {
          width: 100%;
          height: 45%;
          object-fit: contain;
        }

        .bank-type {
          height: 28px;

          @media (max-width: 767px) {
            height: 20px;
          }
        }

        .number {
          color: var(--achromat-1);
          font-size: 1.3em;

          @media (max-width: 767px) {
            font-size: 1em;
          }
        }
      }
    }

    .add-card {
      @extend .bank-card;
      align-items: center;
      text-align: center;
      margin-bottom: 1em;

      img {
        max-width: 50%;
      }

      div {
        color: var(--achromat-1);

        @media (max-width: 767px) {
          font-size: 0.8em;
        }
      }
    }
  }

  .card-imitation {
    margin-top: 1.75em;
    position: relative;
    width: fit-content;
    padding-right: 200px;
    padding-bottom: 4em;
    --width: 424px;
    --height: 268px;

    @media (max-width: 767px) {
      --width: 320px;
      --height: 225px;
      padding-right: 180px;
    }

    @media (max-width: 575px) {
      --width: 300px;
      --height: 210px;
      padding-right: 0px;
      padding-bottom: 0px;
    }

    .front {
      background-color: var(--achromat-0);
      border: 1px solid var(--color-1);
      border-radius: 20px;
      padding: 2em;
      width: var(--width);
      height: var(--height);
      position: relative;
      z-index: 1;

      @media (max-width: 767px) {
        padding: 1em;
      }

      img {
        position: absolute;
        bottom: 20%;
        right: 2em;
        max-width: 70px;
        max-height: 25px;
        object-fit: contain;

        @media (max-width: 767px) {
          bottom: 1em;
          right: 1em;
          max-width: 50px;
          max-height: 20px;
        }
      }
    }

    .back {
      position: absolute;
      bottom: 0px;
      right: 0px;
      z-index: 0;
      background-color: var(--achromat-4);
      border: 1px solid var(--achromat-3);
      border-radius: 20px;
      padding: 100px 2em 2em 240px;
      width: var(--width);
      height: var(--height);

      @media (max-width: 767px) {
        padding: 90px 1em 1em 150px;
      }

      @media (max-width: 575px) {
        position: relative;
        bottom: unset;
        right: unset;
        margin-top: 1em;
        padding: 75px 1em 1em 130px;
      }

      &::after {
        content: "";
        height: 50px;
        width: 100%;
        background-color: var(--achromat-3);
        position: absolute;
        top: 30px;
        left: 0px;

        @media (max-width: 767px) {
          top: 20px;
          height: 40px;
        }
      }
    }
  }

  .messages-list {
    list-style: none;
    padding-left: 0px;

    &-preview {
      --bg: var(--total);
      background-color: var(--bg);
      width: 100%;
      display: grid;
      grid-template-columns: var(--width-1) auto var(--width-2);
      grid-template-rows: 1fr 1fr;
      border-top: 1px solid var(--achromat-4);
      border-bottom: 1px solid var(--achromat-4);
      gap: 0 1.5em;
      padding: 1em 2em;
      transition: var(--transition);

      @media (max-width: 1199px) {
        padding: 1em;
        gap: 0 1em;
      }

      @media (max-width: 575px) {
        gap: 0 0.5em;
      }

      &:hover {
        --bg: var(--achromat-0);

        .controls {
          opacity: 1;
        }
      }

      .img {
        grid-column: 1/2;
        grid-row: 1/3;
        position: relative;

        img {
          width: 100%;
          height: var(--width-1);
          object-fit: cover;
          border-radius: 50%;
        }

        .indicator {
          position: absolute;
          bottom: 0px;
          right: 0px;
          width: 2em;
          height: 2em;
          border-radius: 50%;
          background-color: var(--achromat-4);
          border: 4px solid var(--bg);

          @media (max-width: 767px) {
            width: 1.25em;
            height: 1.25em;
            border: 2px solid var(--bg);
          }

          &.online {
            background-color: var(--green);
          }

          &.unread {
            background-color: var(--color-2);
          }
        }
      }

      .header {
        grid-column: 2/3;
        grid-row: 1/2;
        min-width: 0;

        @media (max-width: 575px) {
          h4 {
            font-size: 1.25em;
          }
        }
      }

      .message {
        grid-column: 2/3;
        grid-row: 2/3;
        width: 100%;
        display: flex;
        align-items: center;
        min-width: 0;

        img {
          width: 40px;
          height: 40px;
          object-fit: cover;
          border-radius: 50%;
          margin-right: 1em;

          @media (max-width: 767px) {
            width: 30px;
            height: 30px;
          }

          @media (max-width: 575px) {
            width: 20px;
            height: 20px;
            margin-right: 0.5em;
          }
        }

        div {
          flex: 1;
          display: flex;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .date {
        grid-column: 3/4;
        grid-row: 1/2;

        @media (max-width: 767px) {
          display: none;
        }
      }

      .count {
        grid-column: 3/4;
        grid-row: 2/3;
        display: flex;
        align-items: center;

        span {
          background-color: var(--color-2);
          color: #fff;
          @include flex-center();
          font-size: 16px;
          font-weight: 700;
          width: 1.5em;
          height: 1.5em;
          border-radius: 50%;

          @media (max-width: 767px) {
            font-size: 13px;
          }
        }
      }

      .controls {
        background-color: var(--bg);
        grid-column: 3/4;
        grid-row: 2/3;
        opacity: 0;
        transition: var(--transition);
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        @media (max-width: 1199px) {
          font-size: 13px;
        }

        @media (max-width: 767px) {
          display: none;
        }

        button {
          display: flex;
          align-items: center;
          transition: var(--transition);

          &:hover {
            color: var(--color-1);
          }
        }
      }

      .drop {
        grid-column: 3/4;
        grid-row: 1/2;
      }
    }
  }

  .user-review {
    display: grid;
    grid-template-columns: var(--width-1) auto var(--width-2);
    grid-template-rows: 2em 2em auto;
    gap: 10px 20px;
    border-top: 1px solid var(--achromat-4);
    padding: 1.6em 0px;

    @media (max-width: 767px) {
      grid-template-columns: 120px 1fr;
      grid-template-rows: 2em 2em auto auto;
    }

    @media (max-width: 767px) {
      grid-template-columns: var(--width-1) 1fr;
      grid-template-rows: 2em 2em 2em auto auto;
    }

    .img {
      grid-column: 1/2;
      grid-row: 1/4;

      @media (max-width: 767px) {
        grid-row: 1/3;
      }

      img {
        width: 100%;
        height: var(--width-1);
        border-radius: 50%;
        object-fit: cover;

        @media (max-width: 767px) {
          width: var(--width-1);
        }
      }
    }

    .grid-1 {
      grid-column: 2/3;
      grid-row: 1/2;

      @media (max-width: 767px) {
        grid-column: 2/4;
      }
    }

    .grid-2 {
      grid-column: 2/3;
      grid-row: 2/3;

      @media (max-width: 767px) {
        grid-column: 2/4;
      }
    }

    .grid-3 {
      grid-column: 3/4;
      grid-row: 1/2;

      @media (max-width: 767px) {
        grid-column: 1/2;
        grid-row: 3/4;
      }

      @media (max-width: 575px) {
        grid-column: 1/3;
      }
    }

    .grid-4 {
      grid-column: 3/4;
      grid-row: 2/3;

      @media (max-width: 767px) {
        grid-column: 2/3;
        grid-row: 3/4;
      }

      @media (max-width: 575px) {
        grid-column: 1/3;
        grid-row: 4/5;
      }
    }

    .grid-5 {
      grid-column: 2/3;
      grid-row: 3/4;

      @media (max-width: 767px) {
        grid-column: 1/3;
        grid-row: 4/5;
      }

      @media (max-width: 575px) {
        grid-row: 5/6;
      }
    }

    .grid-6 {
      grid-column: 3/4;
      grid-row: 3/4;

      @media (max-width: 767px) {
        grid-column: 2/3;
        grid-row: 3/4;
        display: flex;
        justify-content: flex-end;
      }

      @media (max-width: 575px) {
        grid-column: 1/3;
        grid-row: 4/5;
      }
    }
  }
}

.message-window {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  border-radius: inherit;

  .top {
    background-color: var(--achromat-0);
    height: 100px;
    padding: 1em 2em;
    @include flex-center();
    justify-content: space-between;

    @media (max-width: 767px) {
      padding: 1em;
      height: 80px;
    }

    @media (max-width: 575px) {
      padding: 0.75em;
      height: 60px;
      font-size: 12px;
    }

    .img {
      width: 60px;
      height: 60px;
      position: relative;

      @media (max-width: 767px) {
        width: 40px;
        height: 40px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
      }

      .indicator {
        position: absolute;
        bottom: 0px;
        right: 0px;
        width: 1em;
        height: 1em;
        border-radius: 50%;
        background-color: var(--achromat-4);
        border: 2px solid var(--total);

        @media (max-width: 767px) {
          width: 0.75em;
          height: 0.75em;
          border: 1px solid var(--total);
        }

        &.online {
          background-color: var(--green);
        }

        &.unread {
          background-color: var(--color-2);
        }
      }
    }
  }

  .middle {
    max-height: 450px;
    display: flex;
    flex-direction: column-reverse;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 2em;

    time {
      &:not(:first-child) {
        margin: 2em 0 2em 0;
      }

      display: block;
      text-align: center;
      margin: 0 0 2em 0;
      color: var(--achromat-3);
    }

    .chat-box-messages .bubble {
      background-color: var(--achromat-0);
    }
  }

  form {
    background-color: var(--achromat-0);
    height: 75px;
    @include flex-center();
    padding: 1em 2em;
    border-top: 1px solid var(--achromat-4);

    @media (max-width: 575px) {
      padding: 0.75em;
      height: auto;
    }

    button {
      font-size: 1.5em;
      margin-left: 0.75em;
    }
  }
}

footer {
  height: var(--f-height);
  background-color: var(--bg-2);
  color: var(--font-light);
  transition: bottom 0.2s linear;

  @media (max-width: 767px) {
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    z-index: 1050;
    background-color: transparent;
  }

  .container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 767px) {
      align-items: flex-start;
    }

    ul {
      font-size: 0.93em;

      li:not(:first-child) {
        margin-left: 2em;

        @media (max-width: 767px) {
          margin-left: 0px;
        }
      }
    }
  }

  nav.mobile {
    border-radius: 1em;
    background-color: var(--bg-2);
    overflow: hidden;

    a,
    button {
      color: var(--font-light);
      font-size: 1.75em;
      @include flex-center();
      padding: 0.6em 0;
      border-bottom: 2px solid transparent;
      opacity: 0.95;
      transition: var(--transition);

      &.active {
        opacity: 1;
        border-bottom: 2px solid var(--font-light);
      }
    }
  }
}

.dev {
  color: inherit;
  font-weight: 400;
  font-size: 0.95em;

  .color {
    color: #59ffe1;
    font-size: 1.1em;
  }
}

.modal {
  &-content {
    background-color: var(--achromat-0);
    border-radius: 1.5em;
    border: 1px solid var(--achromat-4);
  }

  &-body {
    padding: 2em;

    @media (max-width: 575px) {
      padding: 1em;
    }
  }
}

.offcanvas {
  background-color: var(--bg-3);

  &.offcanvas-top {
    height: fit-content;
    width: 544px;
    right: 5vw;
    left: auto;

    .offcanvas-body {
      padding: calc(var(--h-height) + 2em) 0 2em;
    }
  }

  &.offcanvas-bottom {
    height: fit-content;

    .offcanvas-body {
      padding: 2em 0 calc(var(--f-height) + 2em);
    }
  }

  &.offcanvas-end {
    width: 575px;

    .offcanvas-body {
      padding: 2em 0 calc(var(--f-height) + 2em);
    }
  }

  .scrollable-area {
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.validate-wrapper {
  position: relative;
  width: 100%;

  // .validate-wrapper_error
  &_error {
    input {
      border-color: red;
    }

    input::placeholder {
      color: red;
    }

    textarea {
      border-color: red;
    }

    textarea::placeholder {
      color: red;
    }
  }

  .validate-error {
    position: absolute;
    top: 15px;
    right: 50px;
    font-size: 0.8em;
    color: red;
    z-index: 100;
  }
}

.loader {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 99;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
  background-color: #121212;
}

.email-verify-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;

  .validate-wrapper {
    flex: 1 1 75%;
  }

  input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
  }

  button {
    flex: 1 1 25%;
    border-radius: 0 10px 10px 0;
    border: 1px solid var(--achromat-4);
  }
}

.action-alert {
  position: fixed;
  right: 2vw;
  top: 12vh;
  background-color: var(--bg-2);
  max-width: 50vw;
  padding: 15px;
  font-size: 0.8em;
  z-index: 3000;
  border-radius: 10px;
  display: none;
  align-items: center;
  gap: 10px;
  animation: linear;
  animation-name: run;
  animation-duration: 0.2s;

  span {
    line-break: anywhere;
    color: white;
  }

  &.show {
    display: flex;
  }

  // .action-alert__icon
  &__icon {
    width: 20px;
    height: 20px;
    flex: 1 0 auto;
  }

  // .action-alert_success
  &_success {
    .action-alert__icon {
      color: $green;
    }
  }

  // .action-alert_danger
  &_danger {
    .action-alert__icon {
      color: $accent;
    }
  }

  @keyframes run {
    0% {
      right: -300px;
    }

    100% {
      right: 2vw;
    }
  }
}

.action-notification {
  position: fixed;
  right: calc(-300px - 2vw);
  bottom: 2vh;
  background-color: var(--bg-2);
  width: 300px;
  padding: 15px;
  z-index: 3000;
  border-radius: 12px;
  box-shadow: 0 1px 2px #00000026, 0 2px 6px 2px #0000001a;
  transition: right 0.2s ease-in-out;

  display: grid;
  grid: 16px 16px / 42px 400px;
  align-items: center;
  gap: 10px;

  &.show {
    right: 2vw;
  }

  img {
    grid-column: 1 / 1;
    grid-row: 1 / 3;
    width: 42px;
    height: 42px;
    border-radius: 10px;
  }

  span.user {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    color: white;
    font-size: 0.8em;
  }

  span.text {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    color: white;
    font-size: 1em;
  }
}

.user {
  display: flex;
  align-items: center;
  gap: 8px;

  // .user__avatar
  &__avatar {
    width: 32px;
    height: 32px;
    border-radius: 10px;
  }

  // .user__nickname

  &__nickname {
    font-size: 15px;
    // color: var(--user-nickname);
  }
}

.reset-password-button {
  display: flex;
  height: 150px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}

.images-message {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;

  .images-box {
    width: 250px;
    margin-top: 1em;
    cursor: pointer;

    img {
      object-fit: cover;
      border-radius: 8px;
      width: 100%;
      max-height: 166px;
      margin-top: -80px;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
    }

    img:nth-child(even) {
      rotate: 10grad;
    }

    img:nth-child(odd) {
      rotate: -5grad;
    }

    img:first-child {
      margin: 0;
      rotate: none;
    }
  }
}

.link-to-chat {
  grid-column: 1 / 3;
  grid-row: 1 / 3;
}

.chat-box-messages,
.chat-box-reverse-messages {
  flex: inherit;

  .bubble {
    background-color: var(--achromat-0);
    max-width: max-content;
  }
}

.chat-box-user,
.chat-box-reverse-user {
  img {
    width: 39px;
  }
}

.unread-header-conversations-count,
.unread-conversations-count,
.unread-notifications-count {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
}

.unread-notifications-count {
  background-color: var(--font-light);
  border: 2px solid var(--bg-2);
  color: var(--bg-2);
  margin: 20px 0 0 20px !important;

  position: absolute;
}

.unread-conversations-count {
  background-color: var(--color-2);
  color: #fff !important;
}

.unread-header-conversations-count {
  background-color: var(--font-light);
  color: var(--bg-2);
  margin-left: 1em;
}

.chat-space {
  display: flex;
  flex-direction: column-reverse;
  overflow-x: hidden;
}

.chat-user-name {
  font-size: 11px;
  max-width: 39px;
}

.swal-button {
  border-radius: 20px;
  padding: 20px 24px;
}

.descriptions-lot {
  font-size: 16px;
  font-weight: 500;
  text-align: justify;
  .game-name-lot {
    margin-top: 15px;
  }
}

.description-lot-input {
  display: block;
  width: 100%;
  overflow: hidden;
  resize: none;
  min-height: 45px;
  line-height: 20px;
  border-radius: 10px;
  border: 1px solid #545454;
  align-items: center;
  padding: 15px 15px;
  outline: none;
  box-sizing: border-box;
}
